import React from 'react';
import YouTube from 'react-youtube';
import "./youtubeplayer.css";

const YouTubePlayer = ({ videoId, onClose, show }) => {
    const opts = {
      height: '80%',
      width: '80%',
      playerVars: {
        autoplay: 1,
      },
    };
  
    return (
      show ? (
        <div className='section-video'>
          <div className="youtube-player">
            <button className="close-button" onClick={onClose}>×</button>
            <div className="youtube-video">
              <YouTube videoId={videoId} opts={opts} />
            </div>
          </div>
        </div>
      ) : null
    );
};
  
export default YouTubePlayer;
