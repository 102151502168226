import React from 'react';
import './Contact.css';
import { Footer } from '../footer/footer';

function Contact() {
  return (
    <div className="contact-page">
      <div className="contact-form-container">
        <h1>Contact Us</h1>
        <p>Drop us a message, or check out our footer for more contact information.</p>

        <div className="contact-form">
          <form>
            <input type="text" placeholder="Your name" />
            <input type="email" placeholder="Your email address" />
            <input type="text" placeholder="Contact number" />
            <textarea placeholder="Your message"></textarea>
            <a href="mailto:joatech.el@gmail.com"><button type="submit">Contact Us</button></a>
          </form>
        </div>
      </div>

      {/* <div className="careers-container">
        <h2>Careers</h2>
        <div className="career-card">
          <img src="./career.png" alt="Drone Icon" />
          <h3>No Vacancies</h3>
          <p>There are currently no vacancies available. Check back soon!</p>
        </div>
      </div> */}
    </div>
  );
}

export default Contact;
