import { Link } from "react-router-dom";
import "./footer.css";
import { FaWhatsapp } from "react-icons/fa";

export function Footer () {
    return(
        <footer className="footer">
          <div className="what">
              <div className="link">
                <a href="https://wa.me/27614178626"  target="_blank"><FaWhatsapp className="icone"/></a>
              </div>
          </div>
        <div className="footer-links">
          <a href="/profile">About Us</a>
          {/* <a href="/services">Services</a> */}
          <a href="/contact">Contact</a>
        </div>
        <div className="personnal">
          <p>&copy; 2024 Joatech Electrical. All Rights Reserved.</p>
          <p>Designed by <a href="https://www.linkedin.com/company/nexadaptronics/?viewAsMember=true" target="_blank">Nexadaptronics</a>  || Hosted by Truehost</p>
        </div>
      </footer>
    )
}