import React from 'react';
import './Profile.css';  // Make sure this path is correct
import logo from "../../medias/AccurateInstallationonOff-ShutterStructure.png"
function Profile() {
  return (
    <div className="profile">
      <section className='presentation'>
        <div className='presentation-sectionn'>
          <p className='tittle1'>put your trust in us </p>
          <p className='tittle2'>Sustainable electrical solutions that reduce costs and environmental impact. Let’s create a brighter future together.</p>
          <p className='tittle3'>Expert electrical installations, repairs, and maintenance services for your home and business. Your safety is our priority.</p>
        </div>
      </section>
      <section className='details'>
        <div className='details-section'>
          <div className='card'>
            <div className='bloc'>
              <p className='tittle1'>Mission</p>
              <p className='tittle2'>Our mission is to be recognized as a leading Electrical contracting company in South Africa, Democratic Republic of Congo and across all Africa, through our services and customer satisfaction.</p>
            </div>
          </div>
          <div className='card'>
            <div className='bloc'>
              <p className='tittle1'>Vision</p>
              <p className='tittle2'>Committed to the needs of our clients, delivering quality jobs on time in a professional manner,
                and bringing solutions to all your home technology problems.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='galery'>
        <p className='text'>Service Gallery</p>
        <div className='galery-section'>
          <div className='card'>
            <img
              src="https://th.bing.com/th/id/OIP.K23H_TOdAe1Ncxdhllp2mAAAAA?rs=1&pid=ImgDetMain"
              alt="Electrical Installations"
            />
          </div>
          <div className='card'>
            <img
              src="https://th.bing.com/th/id/R.2ef6859f33b4633e0d71713faec95083?rik=Vm9YyMkPvd584A&riu=http%3a%2f%2fjmbuildingservices.ie%2fwp-content%2fuploads%2f2015%2f05%2fjmbs-building-maintenance-img01-1024x574.jpg&ehk=J0gcZLH5y%2f%2bu%2bc4kTz99e5iM1WfiJeSgOE8eVEvZ%2bGE%3d&risl=&pid=ImgRaw&r=0"
              alt="Maintenance Services"
            />
          </div>
          <div className='card'>
            <img
              src="https://th.bing.com/th/id/OIP.2e6AAfWJ4hY9VeMDhRsnvAHaE8?rs=1&pid=ImgDetMain"
              alt="Automation Solutions"
            />
          </div>
          <div className='card'>
            <img
              src="https://www.prlog.org/12576133-3-.jpg"
              alt="Specialized Energy Solutions"
            />
          </div>
        </div>

      </section>
      {/* 
      <section className="mission-vision">
        <h2>Mission & Vision</h2>
        <p>Mission Statement: To be recognized as a leading Electrical contracting company in South Africa and DRC, through our services and customer satisfaction.</p>
        <p>Vision Statement: Committed to the needs of our clients, delivering quality jobs on time in a professional manner, and bringing solutions to all your home technology problems.</p>
      </section>

      <section className="goals">
        <h2>Our Goals</h2>
        <ul>
          <li>Strong leadership</li>
          <li>Community work</li>
          <li>Respect for the environment</li>
          <li>Management system improvement</li>
          <li>Employee development</li>
        </ul>
      </section>

      <section className="history-background">
        <h2>History & Background</h2>
        <p>Joatech Electrical was established in 2017 in Cape Town. Over the years, we have grown to become a recognized leader in electrical contracting in South Africa and the DRC.</p>
        <p className="contact-email">Contact Email: joatech.el@gmail.com</p>
      </section> */}
    </div>
  );
}

export default Profile;
