import React from "react";
import "./Services.css";


function Services() {
  return (
    <div className="services">
      <section className="overview">
        <h1>Our Services</h1>
        <p>
          We offer a comprehensive range of services to meet all your electrical
          needs.
        </p>
      </section>
      <section className="service-categories">
        <h2>Our Service Categories</h2>
        <div className="category">
          <h3>Electrical Installations</h3>
          <p>
            We deliver safe, efficient building installations, power
            distribution boards, and metering panels tailored to your needs.
          </p>
        </div>
        <div className="category">
          <h3>Maintenance Services</h3>
          <p>
            Our maintenance services ensure reliability with underground fault
            finding, system upkeep, and standby generator support.
          </p>
        </div>
        <div className="category">
          <h3>Automation Solutions</h3>
          <p>
            Upgrade your home with our automation expertise, including Control 4
            panels, cable management, and smart home technology.
          </p>
        </div>
        <div className="category">
          <h3>Specialized Energy Solutions</h3>
          <p>
            Explore sustainable options with solar installations, inverter
            systems, and maintenance-free chemical earthing.
          </p>
        </div>
      </section>

      <section className="service-gallery">
        <h2>Service Gallery</h2>
        <div className="gallery-images">
          <img
            src="https://th.bing.com/th/id/OIP.K23H_TOdAe1Ncxdhllp2mAAAAA?rs=1&pid=ImgDetMain"
            alt="Electrical Installations"
          />
          <img
            src="https://th.bing.com/th/id/R.2ef6859f33b4633e0d71713faec95083?rik=Vm9YyMkPvd584A&riu=http%3a%2f%2fjmbuildingservices.ie%2fwp-content%2fuploads%2f2015%2f05%2fjmbs-building-maintenance-img01-1024x574.jpg&ehk=J0gcZLH5y%2f%2bu%2bc4kTz99e5iM1WfiJeSgOE8eVEvZ%2bGE%3d&risl=&pid=ImgRaw&r=0"
            alt="Maintenance Services"
          />
          <img
            src="https://th.bing.com/th/id/OIP.2e6AAfWJ4hY9VeMDhRsnvAHaE8?rs=1&pid=ImgDetMain"
            alt="Automation Solutions"
          />
          <img
            src="https://www.prlog.org/12576133-3-.jpg"
            alt="Specialized Energy Solutions"
          />
        </div>
      </section>

      <footer className="service-footer">
        <div className="footer-links">
          <a href="/profile">About Us</a>
          <a href="/services">Services</a>
          <a href="/contact">Contact</a>
        </div>
        <p>&copy; 2024 Joatech Electrical. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default Services;
