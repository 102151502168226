import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Updated import for Routes


import './App.css';
import Navbar from './components/navbare/Navbar';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import Profile from './components/profile/Profile';
import Services from './components/services/Services';
import { Footer } from './components/footer/footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <Routes>  {/* Replaced Switch with Routes */}
          <Route path="/" element={<Home />} />  {/* Updated Route syntax */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;

